@import url('https://fonts.googleapis.com/css2?family=Unbounded:wght@200..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Alegreya+Sans:wght@400;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Outfit:wght@100..900&family=Unbounded:wght@200..900&display=swap');

*,
body {
  font-family: "Outfit" !important;
}

:root {
  --main-color: #55AD9B;
  --disable-color: #ca6500;
  --light-color: rgb(255, 247, 238);
  --dark-main-color: #000000;
}


h2 {
  font-weight: 600;
  margin: 0
}

h3 {
  font-weight: 600;
  margin: 0
}


/* src/Header.css */
.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: sticky !important;
  z-index: 99 !important;
  top: 0 !important;
  /* width: 95%; */
  /* margin: 1% auto; */
  padding: 5px 10px;
  background: linear-gradient(to right, #f7ffed, #FFFFFF);
  /* background-color: #F7FFED; */
}

.logo {
  font-size: 24px;
  font-weight: bold;
}

.hero-section {
  background: linear-gradient(to right, #f7ffed, #FFFFFF);
}

.nav {
  display: flex;
  align-items: center !important;
}

.nav-item {
  margin: 0 10px;
  text-decoration: none;
  color: black;
  font-size: 15px;
}

.cta-button {
  padding: 12px 16px;
  background-color: var(--main-color);
  color: white;
  border: 2px solid var(--main-color);
  border-radius: 5px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s;
}

.cta-button:hover {
  background-color: var(--dark-main-color);
  border: 2px solid var(--dark-main-color);
  color: white;
}

.menu-toggle {
  display: block;
  font-size: 24px;
  background: none;
  border: none;
  cursor: pointer;
}

@media (min-width: 769px) {
  .menu-toggle {
    display: none;
  }
}

@media (max-width: 768px) {
  .nav {
    display: none;
  }

  .header {
    justify-content: space-between;
  }

  .logo {
    font-size: 20px;
  }

  .cta-button {
    padding: 6px 12px;
    font-size: 14px;
  }
}



.rotate-img {
  /* transform: rotate(-20deg); */
}

.testimonialsSection {
  width: 100%;
  margin: auto;
  display: flex;
  justify-content: left;
  align-items: center;
  text-align: left;
  padding: 0;
}

.featuresHeading {
  font-size: 45px;
  color: black;
  font-weight: 600;
  margin-bottom: 0;
  line-height: 1.1em;
}

.gradientText {
  background: linear-gradient(to right, #68fff2, #217967);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  -moz-background-clip: text;
  -moz-text-fill-color: transparent;
  background-clip: text;
  color: transparent
}

.gradientTextV2 {
  background: linear-gradient(to right, #0066ff, #8400ff);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  -moz-background-clip: text;
  -moz-text-fill-color: transparent;
  background-clip: text;
  color: transparent
}

.blurDiv {
  height: 600px;
  width: 600px;
  position: absolute;
  background: #f5ffe8;
  border-radius: 50%;
  filter: blur(150px);
  left: 30%
}

.blurDiv2 {
  height: 300px;
  width: 300px;
  position: absolute;
  background: #55AD9B;
  border-radius: 50%;
  filter: blur(150px);
  /* background: #55AD9B; */
  z-index: -2;
}

.milk-wave-img {
  mix-blend-mode: multiply;
}








@keyframes float {
  0%, 100% {
      transform: translateY(0);
  }
  50% {
      transform: translateY(-10px);
  }
}

@keyframes floatReverse {
  0%, 100% {
      transform: translateY(0) rotate(-15deg);
  }
  50% {
      transform: translateY(10px) rotate(-15deg);
  }
}

@keyframes fadeIn {
  from {
      opacity: 0;
  }
  to {
      opacity: 1;
  }
}

@keyframes pulse {
  0% {
      transform: scale(1);
  }
  50% {
      transform: scale(1.05);
  }
  100% {
      transform: scale(1);
  }
}

@keyframes waveMotion {
  0% {
      transform: translateX(0);
  }
  100% {
      transform: translateX(-20px);
  }
}

@keyframes waveMotionReverse {
  0% {
      transform: translateX(0);
  }
  100% {
      transform: translateX(20px);
  }
}







@keyframes fadeInLeft {
  0% {
      opacity: 0;
      transform: translateX(-100px);
  }
  100% {
      opacity: 1;
      transform: translateX(0);
  }
}

@keyframes fadeInRight {
  0% {
      opacity: 0;
      transform: translateX(100px);
  }
  100% {
      opacity: 1;
      transform: translateX(0);
  }
}

